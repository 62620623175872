import { Component, OnInit } from '@angular/core';
import { TemplateService } from '../shared/services/template.service';
import { Router, NavigationStart } from '@angular/router';
import { CookieService } from '../shared/services/cookie-service';
import swal from 'sweetalert2';

export type HeaderType = 'header-default' | 'header-primary' | 'header-info' | 'header-success' | 'header-danger' | 'header-dark';
export type SideNavType = 'sidenav-default' | 'side-nav-dark';

@Component({
    selector: 'app-dashboard',
    templateUrl: './enduser-layout.component.html'
})

export class EndUserLayoutComponent implements OnInit {

    headerThemes = ['header-default', 'header-primary', 'header-info', 'header-success', 'header-danger', 'header-dark'];
    sidenavThemes = ['sidenav-default', 'side-nav-dark'];
    headerSelected: HeaderType;
    sidenavSelected : SideNavType;
    isCollapse : boolean;
    rtlActived: boolean = false;

    themeConfigOpen: boolean = false;

    constructor(private _cookie:CookieService,private tplSvc: TemplateService,private router: Router) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                // console.log('here')
                // this.checkSessionCookies();
            }
        });
    }

    ngOnInit(){
        this.tplSvc.isSideNavCollapseChanges.subscribe(isCollapse => this.isCollapse = isCollapse);
    }

    changeHeader(headerTheme:HeaderType) {
        this.headerSelected = headerTheme;
    }

    changeSidenav(sidenavTheme:SideNavType) {
        this.sidenavSelected = sidenavTheme;
    }

    toggleThemeConfig() {
        this.themeConfigOpen = !this.themeConfigOpen;
    }

    checkSessionCookies(){
        const loggeduser = this._cookie.GetCookies('loggeduser');
        if(loggeduser=='') {
            window.location.href="/extension/sign-in";
        }
    }
}