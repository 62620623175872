import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-dashboard',
    templateUrl: './demo-layout.component.html',
    styleUrls:['./demo-layout.component.css']
})
export class DemoLayoutComponent implements OnInit {
    public currentYear = new Date().getFullYear();
    constructor() { }

    ngOnInit(): void { }

    scroll(id) {
        console.log(`scrolling to ${id}`);
        let el = document.getElementById(id);
        el.scrollIntoView();
      }
}
