import { Component, ViewEncapsulation } from '@angular/core';
import { TemplateService } from '../../shared/services/template.service';
import { SigninService } from 'src/app/account/sign-in/sign-in.service';
import { PaymentService } from 'src/app/subscribe/payment.service';
import { ActivatedRoute, NavigationEnd, Params, Router, RoutesRecognized } from '@angular/router';
import swal from 'sweetalert2';
import * as moment from 'moment';
import 'datatables.net'
import { DynamicScriptLoaderService } from 'src/app/shared/services/dynamic-script-load-service';
import { CookieService } from 'src/app/shared/services/cookie-service';
import { HostListener } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { GlobalConstant } from 'src/app/shared/global-constant';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { ExtensionService } from 'src/app/extension/extension.service';
declare var $: any; // JQuery

@Component({
    selector: 'app-ext-header',
    templateUrl: './ext-header.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['../../../assets/css/animate.min.css',
        '../../../assets/css/themify-icons.css',
        '../../../assets/css/ei-icon.css',
        '../../../assets/css/style.override.css'
    ]
})
export class ExtHeaderComponent {
    lastCookie: any;
    searchModel: string;
    isCollapse: boolean;
    isOpen: boolean;
    accountExpiredFlag: boolean = false;;
    searchActived: boolean = false;
    userName: string;
    userEmail: string;
    page_title: string;
    userProfile: any;
    fname_cnt: string;
    lname_cnt: string;
    subscriptionName: string = '';
    subscriptionExpiry: string = '';
    isFreeTrial: boolean = false;
    expireText: string = "Expires";
    notificationList = [];
    token: string;

    constructor(
        private _cookie: CookieService,
        private ngxUiLoaderService: NgxUiLoaderService,
        private tplSvc: TemplateService,
        private signInService: SigninService,
        private paymentService: PaymentService,
        private _router: Router,
        private dynamicScriptLoader: DynamicScriptLoaderService,
        private titleService: Title,
        private activatedRoute: ActivatedRoute,
        private extensionService: ExtensionService
    ) {


      this.activatedRoute.url.subscribe(activeUrl =>{
        let urlwithtoken = window.location.pathname;

        if(urlwithtoken.indexOf("profile") > 1){
          urlwithtoken = urlwithtoken.split('profile')[1].replace(/^\/|\/$/g, '');
        }
        else if(urlwithtoken.indexOf("dashboard")  > 1){
          urlwithtoken = urlwithtoken.split('dashboard')[1].replace(/^\/|\/$/g, '');
        }
        else if(urlwithtoken.indexOf("billing")  > 1){
          urlwithtoken = urlwithtoken.split('billing')[1].replace(/^\/|\/$/g, '');
        }
        else if(urlwithtoken.indexOf("account-setting")  > 1){
          urlwithtoken = urlwithtoken.split('account-setting')[1].replace(/^\/|\/$/g, '');
        }

        else {}

        this.token = urlwithtoken;
      });




      this._router.events.subscribe((e) => {
        if (e instanceof NavigationEnd) {
          this.getUserShortDetail();
        }
      });

    }

    ngOnInit(){

      // this.activatedRoute.queryParams.subscribe(params => {
      //   let productid = params['token'];

      //   console.log(productid);// OUTPUT 1534
      // });

      // let token = this.activatedRoute.snapshot.params.token;
      // console.log(token);


      // this.activatedRoute.paramMap.subscribe(params => {
      //   console.log(params.get('token'));
      //  });

        // this.activatedRoute.params.subscribe((params: Params) => {
        //   this.token = params["token"];
        //   //console.log(this.token);
        // });

        this.loadScripts();
        this.getUserShortDetail();
        this.tplSvc.isSideNavCollapseChanges.subscribe(isCollapse => this.isCollapse = isCollapse);
        this.tplSvc.isSidePanelOpenChanges.subscribe(isOpen => this.isOpen = isOpen);

        const logged = this._cookie.GetCookies('loggeduser');
        this.userProfile = logged;
        let clientId = this.userProfile.companydetails.companyid;

        if (this._cookie.checkLoginCookies()) {

            // this.checkSubscription(logged.key, clientId);
            var fName = this.userProfile.firstname.slice(0, 10);
            this.userName = fName.length == 10 ? fName + '..' : fName;
            this.userEmail = this.userProfile.email;
            if (this.userProfile.companydetails.completed == false) {
                this.notificationList.push({ message: "Profile completion is pending!", src: "/profile" })
            }
        } else {
            this._cookie.logoutPopUp();
        }

        $('.c2c-tool-tip').tooltip('enable');

        this.page_title = this.titleService.getTitle();

        this._router.events.pipe(
          filter(event => event instanceof NavigationEnd),
        ).subscribe(() => {
          const rt = this.getChild(this.activatedRoute);
          rt.data.subscribe(data => {
            //console.log(data.title);
            //this.titleService.setTitle(data.title);
            this.page_title = this.titleService.getTitle()
          });
        });


    }

    getChild(activatedRoute: ActivatedRoute) {
      if (activatedRoute.firstChild) {
        return this.getChild(activatedRoute.firstChild);
      } else {
        return activatedRoute;
      }

    }


    getUserShortDetail() {


      this.ngxUiLoaderService.start();
      this.extensionService.GetUserProfile(this.token).subscribe(res => {
        const resp: any = res;
//console.log(this.token);

        if (resp.status == "200") {
          this.userProfile = resp.userprofile;
          this.fname_cnt = this.userProfile.firstname,
          this.lname_cnt = this.userProfile.lastname

        } else if (resp.status == "201") {
          swal("Error", resp.message.join("<br/>"), "error");
        } else {

        }
      });
    }

    toggleSideNavCollapse() {
        this.isCollapse = !this.isCollapse;
        this.tplSvc.toggleSideNavCollapse(this.isCollapse);
    }

    toggleSidePanelOpen() {
        this.isOpen = !this.isOpen;
        this.tplSvc.toggleSidePanelOpen(this.isOpen);
    }

    toggleSearch() {
        this.searchActived = !this.searchActived;
    }

    logoutUser() {
        const logged = this._cookie.GetCookies('loggeduser');
        this.ngxUiLoaderService.start();
        if (logged != null) {
            this.signInService.LogoutUser(logged.key).subscribe(
                (data) => {
                    this.ngxUiLoaderService.stop();

                    const resp: any = data;
                    if (resp.status == "200" || resp.status == "201" || resp.status == "202") {
                        this._cookie.isLoggedOut = true;
                        this.navigateToLogin();
                    }
                }, (error) => {
                    this.navigateToLogin();
                }
            );
        } else {
            this.navigateToLogin();
        }
    }


    navigateToLogin() {
        this._cookie.logOutCookies();
        this._router.navigate(["/account/sign-in"]);
    }

    private loadScripts() {
        // You can load multiple scripts by just providing the key as argument into load method of the service
        this.dynamicScriptLoader.load('data-table').then(data => {
            // Script Loaded Successfully
        }).catch(error => console.log(error));
    }

    MakePayment() {
        this.ngxUiLoaderService.start();
        const logged = this._cookie.GetCookies('loggeduser');
        this.paymentService.makePaymentToPaypal(logged.userid, logged.companydetails.companyid).subscribe((data) => {
            const res: any = data;

            if (res.response == "Success" && res.status == "200") {
                this.ngxUiLoaderService.start();
                window.location = res.uri
            }else if (res.status == "201") {
                swal("Error", res.message.join("<br/>"), "error");
              } else {
                swal({
                  title: "Session Expired",
                  text: "Session expired please login again.",
                  type: "error",
                allowEscapeKey : false,
                allowOutsideClick: false}).then((result) => {
                  if(result.value){
                    this._cookie.logOutCookies();
                    this._router.navigate(["/account/sign-in"]);
              }});
              }
        }, (error) => {
            swal({
                title: "Error",
                html: GlobalConstant.ErrorMsg500,
                type: "error"
            })
        });
    }

    checkCookie() {

        this.lastCookie = document.cookie; // 'static' memory between function calls
        var a = 'loggeduser';
        var con = this.lastCookie.indexOf(a) >= 0;
        if (con == false) {
            //console.log(window.location.href)
            //    window.location.reload()
        }
    }

    navigateToTestDrive() {
        // window.open("https://drive.contexttocall.com/signin", "_blank");
    }


}
