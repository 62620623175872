import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs-compat/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import { SettingsService } from 'src/app/subscribe/settingsservice';
import { environment } from 'src/environments/environment';
import swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class CookieService {

  isLoggedOut: boolean = false;
  isExpiringPopupShow: boolean = false;
  isExpiredPopupShow: boolean = false;
  isCloseExpiredPopup: boolean = false;

  constructor(private _sService: SettingsService) { }

  parseISOString(s) {
    var b = s;
    if (!this._sService.environment.production) {
      b = s.replace('IST', '');
    }
    //  var dt = new Date(b).toLocaleString();
    //  return dt;
    return new Date(b);
  }

  SetCookies(key, value) {
    // var ddt = new Date();
    // ddt.setHours(ddt.getHours() + 4);
    // this._service.set(key,value,ddt);
    var secure = "";
    if (this._sService.environment.secureCookies) {
      secure = "secure;"
    }

    var ddt = new Date();
    ddt.setHours(ddt.getHours() + environment.sessionTimeout);
    localStorage.setItem('ExpireTime', JSON.stringify(ddt));
    var expires = "expires=" + ddt.toUTCString();
    document.cookie = key + "=" + value + ";" + secure + expires + ";path=/;";
  }

  SetCookiesTime(key, value, ddt) {
    var secure = "";
    if (this._sService.environment.secureCookies) {
      secure = "secure;"
    }
    //  this._service.set(key,value,time);
    var expires = "expires=" + ddt.toUTCString();
    document.cookie = key + "=" + value + ";" + secure + expires + ";path=/;SameSite=Strict";
  }

  GetCookies(cname) {
    // const _data =this._service.get(key);
    // if(_data !="")
    // {
    // return   JSON.parse(_data);
    // }else{
    //  return _data;

    // }
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        const str = c.substring(name.length, c.length);
        if (this.isJson(str)) {
          return JSON.parse(str);
        } else {
          return str;
        }

      }
    }
    return "";
  }

  isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  GetCookiesSingle(cname) {
    // return this._service.get(key);
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        const str = c.substring(name.length, c.length);
        return str;
      }
    }
    return "";
  }

  DeleteAllCookies(key) {
    var ddt = new Date('01/01/1990');
    var expires = "expires=" + ddt.toUTCString();
    document.cookie = key + "=;" + expires + ";path=/";
  }

  DeleteCookie(cookieName) {
    var ddt = new Date('01/01/1990');
    var expires = "expires=" + ddt.toUTCString();
    document.cookie = cookieName + "=;" + expires + ";path=/";
  }

  logOutCookies() {
    this.DeleteCookie('loggeduser');
    this.DeleteCookie('rmClicked');
    this.DeleteCookie('subscription');
    this.DeleteCookie('freetrial');
    this.DeleteCookie('billingpaypal');

    this.DeleteCookie('__ext');
    
    localStorage.removeItem('ExpireTime');
  }

  checkLoginCookies() {
    const loggedUser = this.GetCookies('loggeduser');
    if (loggedUser != '' && loggedUser != null) {
      return true;
    } else {
      return false;
    }
  }

  logoutPopUp() {
    swal({
      title: "Session Expired",
      text: "Session expired please login again.",
      type: "error",
      allowEscapeKey: false,
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        this.logOutCookies();
        window.location.href = '/account/sign-in';
      }
    });
  }

  expireSession() {
    this.isExpiredPopupShow = true;
    swal({
      title: "Session Expired",
      text: "Please sign in again to continue.",
      type: "error",
      allowEscapeKey: false,
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        this.isExpiredPopupShow = false;
        this.isCloseExpiredPopup = true;
        this.logOutCookies();
        window.location.href = '/account/sign-in';
      }
    });
  }

  expiringSession() {
    this.isExpiringPopupShow = true;
    swal({
      title: "Session Expiring",
      html: "Your session is going to expire in 5 min",
      type: "error",
      allowEscapeKey: false,
      allowOutsideClick: false,
      confirmButtonText: 'Continue'
    }).then((result) => {
      if (result.value) {
        this.isExpiringPopupShow = false;
        const loggedUser = this.GetCookies('loggeduser');
        this.SetCookies('loggeduser', JSON.stringify(loggedUser));
      }
    });
  }
  isCallActive = new BehaviorSubject<boolean>(false);
  isCallActiveObs: Observable<boolean> = this.isCallActive.asObservable();
  liveCallIntervalId:any;
  clearLiveCallInterval(){
    if(this.liveCallIntervalId){
      clearInterval(this.liveCallIntervalId);
    }
  }
}
