import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http'
import { HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';
import { environment } from "src/environments/environment";
@Injectable({
    providedIn: 'root'
  })

  export class PaymentService {
 

    constructor(private http: HttpClient) { 
   
    }  
  
    ConnectToPaypal(): Observable<[]> {      
      const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) };  
      return this.http.get<[]>(environment.apiUrl + '/payment/paypal/connect',  
      httpOptions);
    }  
   
    makePaymentToPaypal(userId,companyId): Observable<[]> {  
      const data ={
        "clientid": companyId,
        "userid": userId
      }    
     
      const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) };  
      return this.http.post<[]>(environment.apiUrl + '/payment/paypal/makepayment',  
      data,httpOptions);
    }  
    GetPaymentDetaild(transactionId): Observable<[]> {      
      const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) };  
      return this.http.get<[]>(environment.apiUrl + '/payment/paypal/getpayment/'+transactionId,  
      httpOptions);
    }  

  }
  