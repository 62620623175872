import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SigninService } from 'src/app/account/sign-in/sign-in.service';
import swal from 'sweetalert2';
import { CookieService } from 'src/app/shared/services/cookie-service';
import { GlobalConstant } from 'src/app/shared/global-constant';
@Component ({
    selector: 'initialize-app',
    templateUrl: 'initialize.html',
    encapsulation: ViewEncapsulation.None,
})

export class InitializeComponent {
    _password:string;
  
    validate:boolean=false;
    constructor(private signinService:SigninService,private _cookie:CookieService) { }
  ngOnInit() {
   
    const lastAccess:any =this._cookie.GetCookies('lastvisited');
  
    if(lastAccess !=null )
        {
const today = new Date().toLocaleDateString();
// const loggedDate = new Date(lastAccess);
// const diffMs= Math.abs(<any>loggedDate - <any>today); 
// const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

if(today ===lastAccess)
    {
        this.validate=true;

    }else{
        this.validate=false;
    }


  }
 
}

    validateAccess(){
        console.log(this._password)
        if(this._password !="")
        {
            this.signinService.ValidateAccess(this._password).subscribe((data)=>{
                const res:any = data;
                console.log(res);
                    if(res.status=="200" && res.response=="Success")
                    {
                        var date = new Date();
	                    var midnight = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
                       console.log(midnight)
                        this._password="";
                        this._cookie.SetCookiesTime('lastvisited',JSON.stringify(new Date().toLocaleDateString()),midnight);
                        this.validate=true;
                    }else{

                       
                        swal({
                            title:"Error",
                            html:res.message.join("<br />"),
                            type:"error"
                          })

                    }

            },(error) => {
               
                swal( "Error",GlobalConstant.ErrorMsg500,"error")
            });


        }
     

    }
}
