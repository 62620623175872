import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from './shared/shared.module';
import { TemplateModule } from './template/template.module';
import { TemplateService } from './shared/services/template.service';

//Layout Component
import { CommonLayoutComponent } from './layouts/common-layout.component';
import { AssociateLayoutComponent } from './layouts/associate-layout.component';
import { AccountLayoutComponent } from './layouts/account-layout.component';
import { HomeLayoutComponent } from './layouts/home-layout.component';

// Routing Module
import { AppRoutes } from './app.routing';

// App Component
import { AppComponent } from './app.component';
import { AuthGuard, PaymentSetupAuthGuard, ManagerLoginAuthGuard, PreLoginAuthGuard } from './shared/services/auth-guard.service';
import { DemoLayoutComponent } from './layouts/demo-layout.component';
import { NgxUiLoaderModule, NgxUiLoaderConfig } from 'ngx-ui-loader';
import { Cards_Directives } from './shared/directives/cards.directive';
import { httpInterceptorProviders } from './shared/services/httpInterceptorProviders';
import { DynamicScriptLoaderService } from './shared/services/dynamic-script-load-service';
import { InitializeComponent } from './home/index/initialize.component';
import { StyleService } from './shared/services/style.service';
import { ManualLayoutComponent } from './layouts/manual-layout.component';
import { GlobalErrorHandler } from './shared/services/globalErrorHandler';
import { SettingsService } from './subscribe/settingsservice';
import { ErrorComponent } from './error/error.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FreeTrialAuthGuard } from './shared/services/freetrialguard.service';
import { HttpCancelService } from './shared/services/httpcancelservice';
import { ManageHttpInterceptor } from './shared/services/managehttp.interceptor';



import { ModalModule } from 'ngx-bootstrap';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// import { PlansPricingComponent } from './plans-pricing/plans-pricing.component';
import { TitleCasePipe, UpperCasePipe } from '@angular/common';
import { EndUserLayoutComponent } from './layouts/enduser-layout.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { Ng5SliderModule } from 'ng5-slider';
import { NgxBreadcrumbModule } from "ngx-dynamic-breadcrumb";

// recaptcha v3
import { FormsModule } from '@angular/forms';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { environment } from '../environments/environment';
import { AndroidDocsComponent } from './manual/android-docs/android-docs.component';
import { initializeApp } from 'firebase/app';
initializeApp(environment.firebase);

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
    bgsColor: '#088cf4',
    // bgsOpacity: 0.5,
    // bgsPosition: POSITION.bottomCenter,
    // bgsSize: 60,
    // bgsType: SPINNER.rectangleBounce,
    fgsColor: '#088cf4',
    // fgsPosition: POSITION.centerCenter,
    // fgsSize: 60,
    // fgsType: SPINNER.chasingDots,
    // logoUrl: 'assets/angular.png',
    pbColor: '#088cf4',
    // pbDirection: PB_DIRECTION.leftToRight,
    // pbThickness: 5,
    // text: 'Welcome to ngx-ui-loader',
    // textColor: '#FFFFFF',
    // textPosition: POSITION.centerCenter
};

@NgModule({
    imports: [
        BrowserModule,
        RouterModule.forRoot(AppRoutes, { useHash: false }),
        SharedModule,
        TemplateModule,
        NgbModule,
        ModalModule.forRoot(),
        NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
        // NgxUiLoaderModule,
        SlickCarouselModule,
        Ng5SliderModule,
        NgxBreadcrumbModule.forRoot(),
        RecaptchaV3Module
    ],
    declarations: [
        AppComponent,
        CommonLayoutComponent,
        AssociateLayoutComponent,
        AccountLayoutComponent,
        HomeLayoutComponent,
        ManualLayoutComponent,
        DemoLayoutComponent,
        Cards_Directives,
        InitializeComponent,
        ErrorComponent,
        EndUserLayoutComponent,
      //  AndroidDocsComponent
        //,PlansPricingComponent
    ],
    providers: [
        {provide: ErrorHandler, useClass: GlobalErrorHandler},
        {provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptcha.siteKey,},
        TemplateService,
        AuthGuard,
        PreLoginAuthGuard,
        ManagerLoginAuthGuard,
        PaymentSetupAuthGuard,
        TitleCasePipe,
        Title,
        UpperCasePipe,
        FreeTrialAuthGuard,
        httpInterceptorProviders,
        DynamicScriptLoaderService,
        StyleService,
        SettingsService,
        HttpCancelService,
        { provide: HTTP_INTERCEPTORS, useClass: ManageHttpInterceptor, multi: true }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
