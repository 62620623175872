import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, Router, PRIMARY_OUTLET, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { debounceTime, take } from "rxjs/operators";
import { CookieService } from "./cookie-service";

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private cookieService: CookieService, private router: Router) {
        this.router = router;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {

        if (this.cookieService.checkLoginCookies()) {
            const loggedUser = this.cookieService.GetCookies('loggeduser');
            return true;
        } else {
            this.router.navigate(['account/sign-in']);
        }

        // const loggedUser = this.cookieService.GetCookies('loggeduser');
        // if (loggedUser.companydetails.subscribed) {
        //     return true;
        // } else {
        //     const navigateUrl = (loggedUser.companydetails.subscribed == false) ? 'account/setup' : 'account/sign-in';
        //     this.router.events.pipe(debounceTime(50),take(1)).toPromise().then(() => {
        //         return this.router.navigate([navigateUrl], { queryParams: { returnUrl: state.url } });
        //     });
        // }

        // if (!this.router.navigated) {
        // } else {
        //     const loggedUser = this.cookieService.GetCookies('loggeduser');
        //     if (loggedUser != '' && loggedUser != null) {
        //         return true;
        //     } else {
        //         this.router.navigate(['account/sign-in'], { queryParams: { returnUrl: state.url } });
        //     }
        // }
    }
}


@Injectable()
export class PreLoginAuthGuard implements CanActivate {

    constructor(private cookieService: CookieService, private router: Router) {
        this.router = router;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        if (this.cookieService.checkLoginCookies()) {
            const loggedUser = this.cookieService.GetCookies('loggeduser');
            if (loggedUser.companydetails.subscribed) {
                this.router.navigate(['/dashboard']);
            } else {
                this.router.navigate(['account/setup']);
            }
        } else {
            return true;
        }
    }

}

@Injectable()
export class PaymentSetupAuthGuard implements CanActivate {

    constructor(private cookieService: CookieService, private router: Router) {
        this.router = router;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {

        if (this.cookieService.checkLoginCookies()) {
            const loggedUser = this.cookieService.GetCookies('loggeduser');
            if (loggedUser.companydetails.subscribed) {
                this.router.navigate(['/dashboard']);
            } else {
                return true;
            }
        } else {
            this.router.navigate(['account/sign-in']);
        }

    }

}

@Injectable()
export class ManagerLoginAuthGuard implements CanActivate {

    constructor(private cookieService: CookieService, private router: Router) {
        this.router = router;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {

        if (this.cookieService.checkLoginCookies()) {
            const loggedUser = this.cookieService.GetCookies('loggeduser');
            if (loggedUser.roledetails.roleid == "5d833b39b4d4ec527cedb13b") {
                if (loggedUser.companydetails.subscribed) {
                    return true;
                } else {
                    this.router.navigate(['account/setup']);
                }
            } else {
                this.router.navigate(['account/sign-in']);
            }
        } else {
            this.router.navigate(['account/sign-in']);
        }
    }

}

@Injectable()
export class AssociateLoginAuthGuard implements CanActivate {

    constructor(private cookieService: CookieService, private router: Router) {
        this.router = router;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {

        if (this.cookieService.checkLoginCookies()) {
            const loggedUser = this.cookieService.GetCookies('loggeduser');
            if (loggedUser.roledetails.roleid == "5d833ba67e0c5f527ca5003c") {
                return true;
            } else {
                this.router.navigate(['account/sign-in']);
            }
        } else {
            this.router.navigate(['account/sign-in']);
        }
    }

}

