import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';

import { HeaderComponent } from './header/header.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { SidePanelComponent } from './side-panel/side-panel.component';
import { FooterComponent } from './footer/footer.component';
import { Sidebar_Directives } from '../shared/directives/side-nav.directive';
import { AssociateSideNavComponent } from './side-nav/associate-side-nav.component';
import { AssociateHeaderComponent } from './header/associate-header.component';
import { EndUserSideNavComponent } from './side-nav/enduser-side-nav.component';
import { ExtHeaderComponent } from './header/ext-header.component';
import { ExtUserRoutes } from '../extension/user/extuser.routing';
import { ProfileComponent } from '../extension/user/profile/profile.component';
import { ExtUserModule } from '../extension/user/extuser.modules';

@NgModule({
    imports: [
        SharedModule,
        RouterModule,
    ],
    exports: [
        HeaderComponent,
        SideNavComponent,
        AssociateSideNavComponent,
        EndUserSideNavComponent,
        SidePanelComponent,
        FooterComponent,
        ExtHeaderComponent,
        Sidebar_Directives,
        AssociateHeaderComponent,
    ],
    declarations: [
        HeaderComponent,
        SideNavComponent,
        AssociateSideNavComponent,
        EndUserSideNavComponent,
        SidePanelComponent,
        FooterComponent,
        Sidebar_Directives,
        AssociateHeaderComponent,
        ExtHeaderComponent
    ],
    providers: []
})


export class TemplateModule { }
