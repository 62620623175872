import { Component } from '@angular/core';
import { TemplateService } from '../../shared/services/template.service';
import { Router, Event, NavigationEnd } from '@angular/router';
import { environment } from "src/environments/environment";
import { DynamicScriptLoaderService } from 'src/app/shared/services/dynamic-script-load-service';
declare let C2C: any;
declare const createPoint: any;

@Component({
    selector: 'app-side-nav',
    templateUrl: './side-nav.component.html',
//     styleUrls:[
//     '../../../assets/css/themify-icons.css',
//     '../../../assets/css/ei-icon.css'
// ]
})
export class SideNavComponent  {

    isCollapse: boolean

    call_label_id:any;
    email_label_id:any;


    constructor(private tplSvc: TemplateService, private router: Router, private dynamicScriptLoader: DynamicScriptLoaderService) {
      this.call_label_id=environment.c2c_call_label_id;
      this.email_label_id=environment.c2c_email_label_id;

        router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                if (window.innerWidth < 992) {
                    this.tplSvc.toggleSideNavCollapse(false);
                }
            }
        })
    }

    ngOnInit() {
        this.loadScripts();
        this.tplSvc.isSideNavCollapseChanges.subscribe(isCollapse => this.isCollapse = isCollapse);
    }

    toggleSideNavCollapse() {
        this.isCollapse = !this.isCollapse;
        this.tplSvc.toggleSideNavCollapse(this.isCollapse);
    }

    private loadScripts() {
        // You can load multiple scripts by just providing the key as argument into load method of the service
        this.dynamicScriptLoader.load('c2c-point').then(data => {
            createPoint(environment.c2c_support_point_id,environment.c2c_support_channel_id); 
        }).catch(error => console.log(error));
    }

    _goToIntegrationdoc() {
        const newWindow = window.open('/manual/docs', '_blank');
       
        //  setTimeout(() => {
        //   // Wait for the page to load and the element to be available
        //   const waitForElement = () => {
        //     const contactDiv = newWindow.document.querySelector("#item-3-8");//getElementById("item-3-8");
        //     if (contactDiv) {
        //       console.log('contactDiv', contactDiv);
        //       contactDiv.scrollIntoView({ behavior: "smooth" });
        //     } else {
        //       // Element not found, retry after a short delay
        //       setTimeout(waitForElement, 1000); // You can adjust the delay as needed
        //     }
        //   };
        //   waitForElement();
        // }, 10000); // Adjust the delay as needed
      }
}
