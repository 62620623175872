import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { HttpHeaders } from "@angular/common/http";
import { Observable, from } from 'rxjs';
import { SignIn } from "./sign-in";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SigninService {

  constructor(private http: HttpClient) { }

  LoginUser(userName, password): Observable<[]> {
    const signinObj = {
      "email": userName,
      "password": password
    };

    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<[]>(environment.apiUrl + '/client/user/ur/login', signinObj, httpOptions);
  }
  LogoutUser(authKey): Observable<[]> {
    const auth = { "Auth-Key": authKey }
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Auth-Key': authKey }) };
    return this.http.post<[]>(environment.apiUrl + '/client/user/logout', auth, httpOptions);
  }
  ValidateAccess(_password): Observable<[]> {
    const auth = { "password": _password }
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<[]>(environment.apiUrl + '/landing/support/ur/validate/access', auth, httpOptions);
  }

  getSubscription(auth, clientId): Observable<[]> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const channelInfo = {
      "clientid": clientId
    };
    return this.http.get<[]>(environment.apiUrl + '/utilities/billing/subscription/' + clientId, httpOptions);
  }
  InitiateFreeTrial(trialDays, startdate): Observable<[]> {
    const data = {
      "days": trialDays,
      "startdate": startdate
    }
    console.log(data);
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<[]>(environment.apiUrl + '/utilities/billing/startfree', data, httpOptions);
  }



  //Forgot Pwd Service API
  SendForgetPasswordEmail(email: string): Observable<string> {
    const emailobj = { "email": email };
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<string>(environment.apiUrl + '/client/user/ur/forgot/password',
      emailobj, httpOptions);
  }
  VerifyForgetPasswordLink(key: string): Observable<string> {
    const keyobj = { "key": key };
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<string>(environment.apiUrl + '/client/user/ur/verify/request',
      keyobj, httpOptions);
  }
  ResetPassword(userId: string, newPassword: string, key: string): Observable<string> {
    const userObject = { "userid": userId, "password": newPassword, "key": key };
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<string>(environment.apiUrl + '/client/user/ur/reset/password',
      userObject, httpOptions);
  }
  ResendCreatePasswordLink(email: string): Observable<string> {
    const userObject = { "email": email };
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<string>(environment.apiUrl + '/client/user/create/password', userObject, httpOptions);
  }
}
