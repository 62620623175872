import { Component, ViewEncapsulation } from '@angular/core';


declare var $: any;
@Component({
  templateUrl: 'error.html',
  encapsulation: ViewEncapsulation.None
 
})

export class ErrorComponent {

  

  constructor() {
  }
 
  ngOnInit() {
  }



}
