import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, retry, map } from "rxjs/operators";
import { Router } from "@angular/router";
import swal from "sweetalert2";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { CookieService } from "./cookie-service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private _router:Router, private ngxUiLoaderService: NgxUiLoaderService,private cookieService: CookieService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // if (!req.url.includes(paths.error)) {
    //   return next.handle(req);
    // }

    return next.handle(req).pipe(
      retry(2),catchError((error: HttpErrorResponse) => {
        this.ngxUiLoaderService.stop();
        console.log(error);
        if (error.status !== 401) {
          // 401 handled in auth.interceptor
         // this.toastr.error(error.message);
        }
        return throwError(error);
      }),  map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
         // let camelCaseObject = mapKeys(event.body, (v, k) => camelCase(k));
         // const modEvent = event.clone({ body: camelCaseObject });
         if(event.body){
          
          if(event.body.status=="202" || event.body.status=="400" )
            {
        //       this.ngxUiLoaderService.stopAll();
        //   //    if(event.body.message[0]=="Unauthorized access." ||event.body.message[0]=="Session expired or invalid key."||event.body.message[0]=="Session expired."||event.body.message[0] =="Session expired, please login again.")
        //  //   { 
        //        console.log('Unauthorized access')
        //     //   this.cookieService.logOutCookies();
           
              
        //       swal("Session Expired", event.body.message.join(","),"error").then((result) => {
        //         if(result.value){
        //           this.cookieService.logOutCookies();
        //       //this._router.navigate(['/account/sign-in']); 
        //       window.location.href='/account/sign-in';
        //      // setTimeout(()=>{
        //      //   window.location.reload();
        //      //  },800);    
            
        //     }
        //     });
          
           
        //    }
              return event;
            }else{
            
              return event;
            }
          }else{
        
          return event;
          }
        }
      })
    );
  }
}
