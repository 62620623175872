import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, Router, PRIMARY_OUTLET, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { debounceTime, take } from "rxjs/operators";
import { CookieService } from "./cookie-service";

@Injectable()
export class FreeTrialAuthGuard implements CanActivate {

    private router: Router;

    constructor(private _cookie: CookieService, router: Router) {
        this.router = router;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {


        if (!this.router.navigated) {
            const loggedUser = this._cookie.GetCookies('loggeduser');

            if (loggedUser != '' && loggedUser != null) {

                if (this._cookie.GetCookiesSingle('freetrial') == "true") { return (true); }
                else {

                    this.router.events.pipe(
                        debounceTime(50),
                        take(1)
                    ).toPromise().then(() => {
                        //now that the router has settled, navigate to your new route
                        // return  this.router.navigate([state.url]);
                        return this.router.navigateByUrl("/error")
                    });

                }

            } else {
                this.router.events.pipe(
                    debounceTime(50),
                    take(1)
                ).toPromise().then(() => {
                    //now that the router has settled, navigate to your new route
                    return this.router.navigate(['/account/sign-in'], { queryParams: { returnUrl: state.url } });
                    // return this.router.navigateByUrl("/account/sign-in")   
                });
                //   this.router.navigateByUrl['/'];
                //   return( false );
            }

        } else {


            const loggedUser = this._cookie.GetCookies('loggeduser');

            if (loggedUser != '' && loggedUser != null) {
                return true;
            } else {
                //  this.router.navigateByUrl("/account/sign-in")
                //   this.router.navigate['/account/sign-in'];
                this.router.navigate(['/account/sign-in'], { queryParams: { returnUrl: state.url } });
            }

        }

    }
}