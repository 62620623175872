import { Component, OnInit } from '@angular/core';
import { TemplateService } from '../../shared/services/template.service';
import { Router, Event, NavigationEnd, ParamMap, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-enduser-side-nav',
    templateUrl: './enduser-side-nav.component.html',
})
export class EndUserSideNavComponent implements OnInit {

    token: string;
    isCollapse: boolean

    constructor(private tplSvc: TemplateService, private router: Router, private route: ActivatedRoute) {
        router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                if (window.innerWidth < 992) {
                    this.tplSvc.toggleSideNavCollapse(false);                    
                }
                var urlParams = event.url.split("/");
                this.token = urlParams[3];
            }           
        });        
    }

    ngOnInit() {
        this.tplSvc.isSideNavCollapseChanges.subscribe(isCollapse => this.isCollapse = isCollapse);
    }   

    toggleSideNavCollapse() {
        this.isCollapse = !this.isCollapse;
        this.tplSvc.toggleSideNavCollapse(this.isCollapse);
    }
}
