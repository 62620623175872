import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-manual',
    template: '<router-outlet></router-outlet>',
    styleUrls:['../../assets/css/theme.css','../../../node_modules/font-awesome/css/font-awesome.css'],
   encapsulation: ViewEncapsulation.None,

})
export class ManualLayoutComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {

    }
}
