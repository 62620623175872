import { Component, HostListener, NgZone, OnInit } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, ActivatedRoute } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { CookieService } from './shared/services/cookie-service';
import swal from 'sweetalert2';
import { PlatformLocation } from '@angular/common';
import { filter } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { DynamicScriptLoaderService } from './shared/services/dynamic-script-load-service';
import { environment } from "src/environments/environment";
import { getMessaging, onMessage } from 'firebase/messaging';

declare let C2C: any;
declare var $: any; // JQuery

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

    subscription: Subscription;
    refreshSubscription: Subscription;
    apiSubscription2: Subscription;
    accountExpiredFlag: boolean = false;
    @HostListener('window:popstate', ['$event'])

    loggedProfile: any;
    private broadcastChannel: BroadcastChannel;
    constructor(
      private title: Title,
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private cookieService: CookieService,
      location: PlatformLocation,
      private ngZone: NgZone,
      private dynamicScriptLoader: DynamicScriptLoaderService) {

        this.loggedProfile = this.cookieService.GetCookies('loggeduser');
        const rmChecked = this.cookieService.GetCookies("rmClicked");

        if ((this.loggedProfile == null || this.loggedProfile == '') && (rmChecked == null || rmChecked == "" || rmChecked == false)) {
            this.cookieService.logOutCookies();
        }

        this.broadcastChannel = new BroadcastChannel('firebase-messaging-channel');
        this.listenForMessages();
        router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart) {
                // if (event.url === '/account/sign-in') {
                //     this.loggedProfile = this.cookieService.GetCookies('loggeduser');
                //     if (this.loggedProfile != null && this.loggedProfile != '') {
                //         const today = new Date();
                //         const loggedDate = new Date(this.loggedProfile.loggedTime);

                //         if (today.toLocaleDateString() === loggedDate.toLocaleDateString()) {
                //             const diffMs = Math.abs(<any>loggedDate - <any>today);
                //             const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

                //             if (diffMins < 15) {
                //                 // this.router.navigate(['/dashboard']);
                //             }
                //         }
                //     }
                // }

                const browserRefresh = !router.navigated;
                const routerNavigated = router.navigated;

                // Max 5 min left or session expired and then user refresh the browser
                if (browserRefresh && cookieService.checkLoginCookies()) {
                    if ((300000 >= (new Date(JSON.parse(localStorage.getItem('ExpireTime'))).getTime() - new Date().getTime())) || (new Date().getTime() > new Date(JSON.parse(localStorage.getItem('ExpireTime'))).getTime())) {
                        this.cookieService.logOutCookies();
                        this.router.navigate(['/account/sign-in']);
                    }
                }

                // Cookie time will extend on navigation & refreshing browser
                if (browserRefresh || routerNavigated) {
                    if (cookieService.checkLoginCookies()) {
                        this.loggedProfile = this.cookieService.GetCookies('loggeduser');
                        if (((new Date(JSON.parse(localStorage.getItem('ExpireTime'))).getTime() - new Date().getTime())) > 300000) {
                            this.cookieService.SetCookies('loggeduser', JSON.stringify(this.loggedProfile));
                        }
                        this.subscription = interval(1).subscribe((val) => {
                            if (cookieService.checkLoginCookies()) {
                                if (cookieService.isExpiringPopupShow == false) {
                                    if ((300000 >= (new Date(JSON.parse(localStorage.getItem('ExpireTime'))).getTime() - new Date().getTime())) && (new Date(JSON.parse(localStorage.getItem('ExpireTime'))).getTime() > new Date().getTime())) {
                                        this.cookieService.expiringSession();
                                    }
                                }
                            }
                            if (cookieService.isExpiredPopupShow == false && cookieService.isLoggedOut == false) {
                                if (new Date(JSON.parse(localStorage.getItem('ExpireTime'))).getTime() < new Date().getTime() && cookieService.isCloseExpiredPopup == false) {
                                    this.cookieService.expireSession();
                                }
                            }
                        });
                    }

                    // Browser's back pressed event
                    location.onPopState(() => {
                        if (cookieService.isExpiredPopupShow || cookieService.isExpiringPopupShow) {
                            swal.close();
                            this.cookieService.logOutCookies();
                            this.router.navigate(['/account/sign-in']);
                        }
                    });
                    // this.refreshSubscription= interval(900000).subscribe((x =>{
                }
            }

            // if (event instanceof NavigationEnd) {
            // }

            // if (event instanceof NavigationError) {
            // }
        });

        // refresh a page after few minutes
        // this.reloadComponent();
        // }));
    }

    ngOnInit() {
      //  this.loadScripts();

        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
        ).subscribe(() => {
            const rt = this.getChild(this.activatedRoute);
            rt.data.subscribe(data => {
                //console.log(data.title);
                this.title.setTitle(data.title)
            });
        });
        this.listen();
        this.apiSubscription2 = this.cookieService.isCallActiveObs.subscribe(isActive => {
            if (!isActive) {
                this.cookieService.clearLiveCallInterval();
            } 
        })
    }
    private listenForMessages() {
        this.broadcastChannel.onmessage = (event) => {
            this.ngZone.run(() => {
                console.log('Received background message in Angular app: ', event.data);
                let payload = event.data;
                // Handle the message data as needed
                if (payload.data) {
                    if (payload.data.subject == 'active_call') {
                      this.cookieService.isCallActive.next(true);
                    } else if (payload.data.subject == 'inactive_call') {
                      this.cookieService.isCallActive.next(false);
                    }
                  }
                  if (payload.notification) {
                    if (payload.notification.title == 'active_call') {
                      this.cookieService.isCallActive.next(true);
                    } else if (payload.notification.title == 'inactive_call') {
                      this.cookieService.isCallActive.next(false);
                    }
                  }
            });
        };
    }
    listen() {
        try {
          const messaging = getMessaging();
          onMessage(messaging, (payload) => {
            console.log("Notification received, payload below");
            console.log(payload);
            if (payload.data) {
              if (payload.data.subject == 'active_call') {
                this.cookieService.isCallActive.next(true);
              } else if (payload.data.subject == 'inactive_call') {
                this.cookieService.isCallActive.next(false);
              }
            }
            if (payload.notification) {
              if (payload.notification.title == 'active_call') {
                this.cookieService.isCallActive.next(true);
              } else if (payload.notification.title == 'inactive_call') {
                this.cookieService.isCallActive.next(false);
              }
            }
          });
        } catch (error) {
          console.error('Error in listening for messages:', error);
        }
      }
// Added loadScripts() in side nav component beacuase every time need to refresh nav bar
//     private loadScripts() {
//       // You can load multiple scripts by just providing the key as argument into load method of the service
//       this.dynamicScriptLoader.load('c2c-point').then(data => {
//         var c2c = new C2C(environment.c2c_support_point_id);
//         c2c.add(environment.c2c_support_channel_id);
//       }).catch(error => console.log(error));
//   }

    getChild(activatedRoute: ActivatedRoute) {
        if (activatedRoute.firstChild) {
            return this.getChild(activatedRoute.firstChild);
        } else {
            return activatedRoute;
        }
    }

    onPopState(event) {
        if (this.accountExpiredFlag) {
            window.history.forward();
            console.log('Account Expired cant go back');
        }
    }

    reloadComponent() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([this.router.url]);
    }

    // @HostListener("window:onbeforeunload",["$event"])
    // clearLocalStorage(event){
    //     localStorage.clear();
    // }



}
