import { Routes } from '@angular/router';
import { CommonLayoutComponent } from './layouts/common-layout.component';
import { AccountLayoutComponent } from './layouts/account-layout.component';
import { ManualLayoutComponent } from './layouts/manual-layout.component';
import { ErrorComponent } from './error/error.component';
import { HomeLayoutComponent } from './layouts/home-layout.component';
import { AssociateLayoutComponent } from './layouts/associate-layout.component';
import { EndUserLayoutComponent } from './layouts/enduser-layout.component';

export const AppRoutes: Routes = [
    {
        path: '',
        redirectTo: '/account/sign-in',
        pathMatch: 'full'
    },
    {
        path: '',
        component: HomeLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './home/home.modules#HomeModule'
            },
            {
                path: '',
                loadChildren: './extension/home/exthome.modules#ExtHomeModule'
            }
        ]
    },
    {
        path: '',
        component: CommonLayoutComponent,
        children: [
            {
                path: 'dashboard',
                loadChildren: './dashboard/dashboard.module#DashboardModule',
                data: {
                    title: 'Dashboard ',
                    breadcrumb: 'Dashboard'
                }
            },
            {
                path: 'reports',
                loadChildren: './reports/reports.modules#ReportsModule',
                data: {
                    title: 'Reports ',
                    breadcrumb: 'Reports'
                }
            },
            {
                path: 'billing',
                loadChildren: './billing/billing.modules#BillingModule',
                data: {
                    title: 'Billing',
                    breadcrumb: 'Billing'
                }
            },
            {
                path: 'point-management',
                loadChildren: './configuration/configuration.modules#ConfigurationModule',
                data: {
                  title: 'Setup - Point Management',
                  breadcrumb: 'Setup - Point Management'
                }
            },
            {
                path: 'associates',
                loadChildren: './associate/associate.modules#AssociateModule',
                data: {
                    title: 'Associate Management',
                    breadcrumb: 'Associate Management'
                }
            },
            {
                path: '',
                loadChildren: './account-settings/account-settings.modules#AccountSettingModule',
                data: {
                    title: 'Account Settings',
                    breadcrumb: 'Account Settings'
                }
            }
            //   ,
            //   {
            //     path: 'plans-pricing',
            //     component: PlansPricingComponent
            // }
        ]
    },
    {
        path: '',
        component: AccountLayoutComponent,
        children: [
            {
                path: 'account',
                loadChildren: './account/account.modules#AccountModule',
                data: {
                    title: 'Account',
                    breadcrumb: 'Account'
                }
            },
            {
                path: '',
                loadChildren: './extension/account/extaccount.modules#ExtAccountModule'
            }
        ]
    },
    {
        path: '',
        component: ManualLayoutComponent,
        children: [
            {
                path: 'manual',
                loadChildren: './manual/manual.modules#ManualModule'
            }
        ]
    },
    {
        path: '',
        component: AssociateLayoutComponent,
        children: [
            {
                path: 'associate',
                loadChildren: './associate-login/associate-login.modules#AssociateLoginModule'
            }
        ]
    },
    {
        path: '',
        component: EndUserLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: './extension/user/extuser.modules#ExtUserModule'
            }
        ]
    },



    // {
    //     path: '',
    //     component: AccountLayoutComponent,
    //     children: [
    //         {
    //             path: 'subscribe',
    //             loadChildren: './subscribe/subscribe.modules#SubscribeModule'
    //         }
    //     ]
    // },

    {
        path: '**',
        component: ErrorComponent
    }
];
