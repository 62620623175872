export const environment = {
  abb: "p",
  production: true,
  secureCookies:true,
  feUrl:'https://app.contexttocall.com',
  apiUrl: 'https://apis.contexttocall.com/c2c',
  // captchaKey: "6LfpOmEaAAAAAGsI6JXlMzOl3b7rW4YmYXFDjldD",
  stripePublishableKey: "pk_live_51I1FQsIlfOzCj1KFD3EA425b5iSDqJnVl0raqV1GxFdkhqxDEUNQE5CRfY8gW388Ond3L8Add2qeTXVWKU4AYpH300qx2Rpg4q",
  sessionTimeout: 15, //in mins
  c2c_support_point_id:'60424735f74ac306c1bfa900',
  c2c_support_channel_id:'61e12301f74ac32be1a47596',
  c2c_point_url:'https://bit.ly/3blWnPv',// prod
  c2c_call_label_id:'Fqrzma',// prod
  c2c_email_label_id:'qscNR6',// prod
  c2c_business_email:'Care.business@contexttocall.com',
  c2c_contact_address:'10 N Longfellow Dr: West Windsor NJ 08550 United States',
  c2c_contact_phone:'609-371-5400 x 113',
  androidPackgeName: 'com.c2cb.androidsdk', // Prod
  androidVersion: 'com.github.TroyVGroup:C2CB-AndroidSDK:1.5', // Prod
  iOSVersion: '1.7', // Prod
  recaptcha: {
      siteKey: '6LdgvIAlAAAAACmViig1QXqdwNtvUNX1kqcy7evh', //product.config@contexttocall.com 
    },
  radarAPIKey: 'prj_live_sk_569b6f639edde6120a26f703511c61aaecd3f7ef',
  firebase: {
    apiKey: "AIzaSyDW2L_5jspzFj4pCMAMC84mlLTDIh5g6Ds",
    authDomain: "context-to-call-3efea.firebaseapp.com",
    projectId: "context-to-call-3efea",
    storageBucket: "context-to-call-3efea.appspot.com",
    messagingSenderId: "390559712649",
    appId: "1:390559712649:web:bf3f0fa35e2bba1de70d58",
    measurementId: "G-9Y0598X1Q9",
    vapidKey: "BEAOLsc4FuQ6lZxxa9E7yuHpUFTdJZdIZ3xrUI0iL0zETSpKvNVr214jvdunNgr3NRDU1hdeDr2g9_sr67o_1rU"
  },
};

