import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ExtensionService {

    constructor(private http: HttpClient) { }

    GetUserUsage(token: string): Observable<[]> {
        const params = {
            'Content-Type': 'application/json',
            'Auth-Key': "Bearer " + token
        }
        const httpOptions = { headers: new HttpHeaders(params) };
        return this.http.get<[]>(environment.apiUrl + '/utilities/ext/usage', httpOptions);
    }

    GetUserProfile(token: string): Observable<[]> {
        const params = {
            'Content-Type': 'application/json',
            'Auth-Key': "Bearer " + token
        }
        const httpOptions = { headers: new HttpHeaders(params) };
        return this.http.get<[]>(environment.apiUrl + '/utilities/ext/user', httpOptions);
    }

    UpdateProfile(userProfile: any, token: string): Observable<[]> {
        const userProfileObj = {
            "firstname": userProfile.firstname,
            "lastname": userProfile.lastname,
            "email": userProfile.email,
            "address1": userProfile.address1,
            "address2": userProfile.address2,
            "countryid": userProfile.country,
            "stateid": userProfile.state,
            "city": userProfile.city,
            "zip": userProfile.zip
        };
        const params = {
            'Content-Type': 'application/json',
            'Auth-Key': "Bearer " + token
        }
        const httpOptions = { headers: new HttpHeaders(params) };
        return this.http.put<[]>(environment.apiUrl + '/utilities/ext/update', userProfileObj, httpOptions);
    }

    connectWithStripe(token: string): Observable<[]> {
        const params = {
            'Auth-Key': "Bearer " + token
        }
        const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Auth-Key': "Bearer " + token }) };
        return this.http.post<[]>(environment.apiUrl + '/utilities/ext/stripe/connect', params, httpOptions);

    }


    validateStripeSession(sessionId: string, token: string): Observable<[]> {
        const params = {
            'Content-Type': 'application/json',
            'Auth-Key': "Bearer " + token
        }
        const data = {
            "sessionid": sessionId,
        };
        const httpOptions = { headers: new HttpHeaders(params) };
        return this.http.post<[]>(environment.apiUrl + '/utilities/ext/validate/session', data, httpOptions);
    }

    loadPaymentMethods(token: string): Observable<[]> {
      const params = {
        'Content-Type': 'application/json',
        'Auth-Key': "Bearer " + token
      }
      const httpOptions = { headers: new HttpHeaders(params) };
      return this.http.get<[]>(environment.apiUrl + '/utilities/ext/payment/methods', httpOptions);
    }

    setPaymentDefaultMethod(paymentMethodId: string, token: string): Observable<[]> {
        const params = {
          'Content-Type': 'application/json',
          'Auth-Key': "Bearer " + token
        }
        const data = {
            "methodid": paymentMethodId,
        };
        const httpOptions = { headers: new HttpHeaders(params) };
        return this.http.post<[]>(environment.apiUrl + '/utilities/ext/update/default', data, httpOptions);
    }

    sendEmailVerifyLink(emailval, token: string): Observable<[]> {
      const params = {
        'Auth-Key': "Bearer " + token,
        "email": emailval
      }

      const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Auth-Key': "Bearer " + token }) };
      return this.http.post<[]>(environment.apiUrl + '/utilities/ext/verify/email', params, httpOptions);
    }

    VerifyEmailLink(key:string): Observable<[]>{
      const keyobj={"key":key};
      const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) };
      return this.http.post<[]>(environment.apiUrl + '/utilities/ext/ur/verify/request', keyobj, httpOptions);
    }

    DeleteMethod(paymentMethodId: string, token: string): Observable<[]>{
      const params = {
        'Content-Type': 'application/json',
        'Auth-Key': "Bearer " + token
      }
      const data = {
        "methodid": paymentMethodId,
      };
      const httpOptions = { headers: new HttpHeaders(params) };
      return this.http.post<[]>(environment.apiUrl + '/utilities/ext/detach/method',data, httpOptions);
    }

    VerifySession(token:string): Observable<[]>{
      const params = {
        'Content-Type': 'application/json',
        'Auth-Key': "Bearer " + token
      }

      const httpOptions = { headers: new HttpHeaders(params) };
      return this.http.get<[]>(environment.apiUrl + '/utilities/ext/verify/session', httpOptions);
    }

}
