import { Component, ViewEncapsulation } from '@angular/core';
import { TemplateService } from '../../shared/services/template.service';
import { SigninService } from 'src/app/account/sign-in/sign-in.service';
import { PaymentService } from 'src/app/subscribe/payment.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import swal from 'sweetalert2';
import * as moment from 'moment';
import '../../../../node_modules/datatables.net/js/jquery.dataTables.js'
import { DynamicScriptLoaderService } from 'src/app/shared/services/dynamic-script-load-service';
import { CookieService } from 'src/app/shared/services/cookie-service';
import { HostListener } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { GlobalConstant } from 'src/app/shared/global-constant';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
declare var $: any; // JQuery

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['../../../assets/css/animate.min.css',
        '../../../assets/css/themify-icons.css',
        '../../../assets/css/ei-icon.css',
        '../../../assets/css/style.override.css'
    ]
})
export class HeaderComponent {
    lastCookie: any;
    searchModel: string;
    isCollapse: boolean;
    isOpen: boolean;
    accountExpiredFlag: boolean = false;;
    searchActived: boolean = false;
    userName: string;
    userEmail: string;
    page_title: string;
    userProfile: any;
    subscriptionName: string = '';
    subscriptionExpiry: string = '';
    isFreeTrial: boolean = false;
    expireText: string = "Expires";
    notificationList = [];
    fullName: string;

    constructor(private _cookie: CookieService,
        private ngxUiLoaderService: NgxUiLoaderService,
        private tplSvc: TemplateService,
        private signInService: SigninService,
        private paymentService: PaymentService,
        private _router: Router,
        private dynamicScriptLoader: DynamicScriptLoaderService,
        private titleService: Title,
        private activatedRoute: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.loadScripts();
        this.tplSvc.isSideNavCollapseChanges.subscribe(isCollapse => this.isCollapse = isCollapse);
        this.tplSvc.isSidePanelOpenChanges.subscribe(isOpen => this.isOpen = isOpen);

        const logged = this._cookie.GetCookies('loggeduser');
        this.userProfile = logged;
        let clientId = this.userProfile.companydetails.companyid;
        if (this._cookie.checkLoginCookies()) {
            // this.checkSubscription(logged.key, clientId);


            var fName = this.userProfile.firstname;//.slice(0, 10);
            var lName = this.userProfile.lastname;//.slice(0, 10);

            this.fullName = fName + " "+ lName;
            this.userName = this.userProfile.firstname;

            if (this.userName.length > 10) {
                this.userName = this.userName.substring(0, 10) + '..';
            }
           
            this.userEmail = this.userProfile.email;
            if (this.userProfile.companydetails.completed == false) {
                this.notificationList.push({ message: "Profile completion is pending!", src: "/profile" })
            }
        } else {
            this._cookie.logoutPopUp();
        }

        $('.c2c-tool-tip').tooltip('enable');

        this.page_title = this.titleService.getTitle();

        this._router.events.pipe(
          filter(event => event instanceof NavigationEnd),
        ).subscribe(() => {
          const rt = this.getChild(this.activatedRoute);
          rt.data.subscribe(data => {
            //console.log(data.title);
            //this.titleService.setTitle(data.title);
            this.page_title = this.titleService.getTitle()
          });
        });


    }

    getChild(activatedRoute: ActivatedRoute) {
      if (activatedRoute.firstChild) {
        return this.getChild(activatedRoute.firstChild);
      } else {
        return activatedRoute;
      }

    }

    // checkSubscription(key, clientId) {
    //     this.signInService.getSubscription(key, clientId).subscribe(
    //         (data) => {
    //             const resp: any = data;

    //             if (resp.status == "200") {
    //                 this._cookie.DeleteCookie('freetrial');
    //                 if (resp.currentstate.expired) {
    //                     this.accountExpiredFlag = true;
    //                     this.expireText = "Expired";
    //                     swal({
    //                         title: "YOUR FREE TRIAL HAS EXPIRED!",
    //                         text: 'We hope it helped your business, now please add $69 to activate your Standard Plan',
    //                         // html: "Subscription ended.",
    //                         type: "error",
    //                         showCancelButton: true,
    //                         confirmButtonText: 'Add $69',
    //                         allowOutsideClick: false
    //                     }).then((result) => {
    //                         if (result.value) {

    //                             this.MakePayment();
    //                         } else {
    //                             this.logoutUser();
    //                         }
    //                     });


    //                 }

    //                 if ((resp.currentstate.subscription).split(' ')[0] == 'Free') {
    //                     this.subscriptionName = (resp.currentstate.subscription).split(' ')[0] + " Trial";
    //                     this.subscriptionExpiry = resp.currentstate.enddate;
    //                     this.isFreeTrial = true;
    //                 }
    //                 else if (resp.currentstate.subscription) {
    //                     this.subscriptionName = (resp.currentstate.subscription);
    //                 }
    //                 // console.log(this.subscriptionName)
    //                 this._cookie.SetCookies('subscription', JSON.stringify(resp.currentstate));

    //                 window.setInterval(this.checkCookie, 1000);
    //             } else if (resp.status == 200 && resp.currentstate.subscription == "No Active Subscription" && resp.currentstate.activeon != "") {
    //                 this._cookie.DeleteCookie('freetrial');
    //                 this.subscriptionName = (resp.currentstate.subscription);
    //                 if (resp.currentstate.activeon) {
    //                     this.expireText = "Activate";
    //                     this.subscriptionExpiry = resp.currentstate.activeon;
    //                 }
    //                 this.isFreeTrial = true;


    //             }
    //             else if (resp.status == "200" && resp.currentstate.subscription === "No Active Subscription") {
    //                 this._cookie.SetCookies('subscription', "firsttime");

    //                 window.location.href = '/account/freetrial';

    //             } else if (resp.message == "Invalid client id.") {
    //                 this._cookie.SetCookies('subscription', "firsttime");

    //                 window.location.href = '/account/freetrial';


    //             } else if (resp.message[0] == "No subscription." && this._cookie.GetCookies('subscription') == "firsttime") {
    //                 if (window.location.pathname !== "/license") { window.location.href = "/license"; }
    //             } else if (resp.message[0] == "No subscription.") {
    //                 window.location.href = '/account/freetrial';
    //             }
    //         }
    //         , (error) => {
    //             console.log(error);
    //         }
    //     );

    // }



    toggleSideNavCollapse() {
        this.isCollapse = !this.isCollapse;
        this.tplSvc.toggleSideNavCollapse(this.isCollapse);
    }

    toggleSidePanelOpen() {
        this.isOpen = !this.isOpen;
        this.tplSvc.toggleSidePanelOpen(this.isOpen);
    }

    toggleSearch() {
        this.searchActived = !this.searchActived;
    }

    logoutUser() {
        swal({
            title: "Are you sure?",
            text: "You will be signed out of the application",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes , logout!'
            
        }).then((result)=>{
            if(result.value === true){
                const logged = this._cookie.GetCookies('loggeduser');
                this.ngxUiLoaderService.start();
                if (logged != null) {
                    this.signInService.LogoutUser(logged.key).subscribe(
                        (data) => {
                            this.ngxUiLoaderService.stop();
        
                            const resp: any = data;
                            if (resp.status == "200" || resp.status == "201" || resp.status == "202") {
                                this._cookie.isLoggedOut = true;
                                this.navigateToLogin();
                            }
                        }, (error) => {
                            this.navigateToLogin();
                        }
                    );
                } else {
                    this.navigateToLogin();
                }
            }
        });
       
    }


    navigateToLogin() {
        this._cookie.logOutCookies();
        this._router.navigate(["/account/sign-in"]);
    }

    private loadScripts() {
        // You can load multiple scripts by just providing the key as argument into load method of the service
        this.dynamicScriptLoader.load('data-table').then(data => {
            // Script Loaded Successfully
            console.log('script loaded sucess')
        }).catch(error => console.log(error));


    }

    MakePayment() {
        this.ngxUiLoaderService.start();
        const logged = this._cookie.GetCookies('loggeduser');
        this.paymentService.makePaymentToPaypal(logged.userid, logged.companydetails.companyid).subscribe((data) => {
            const res: any = data;

            if (res.response == "Success" && res.status == "200") {
                this.ngxUiLoaderService.start();
                window.location = res.uri
            }else if (res.status == "201") {
                swal("Error", res.message.join("<br/>"), "error");
              } else {
                swal({
                  title: "Session Expired",
                  text: "Session expired please login again.",
                  type: "error",
                allowEscapeKey : false,
                allowOutsideClick: false}).then((result) => {
                  if(result.value){
                    this._cookie.logOutCookies();
                    this._router.navigate(["/account/sign-in"]);
              }});
              }
        }, (error) => {
            swal({
                title: "Error",
                html: GlobalConstant.ErrorMsg500,
                type: "error"
            })
        });
    }

    checkCookie() {

        this.lastCookie = document.cookie; // 'static' memory between function calls
        var a = 'loggeduser';
        var con = this.lastCookie.indexOf(a) >= 0;
        if (con == false) {
            //console.log(window.location.href)
            //    window.location.reload()
        }
    }

    navigateToTestDrive() {
        // window.open("https://drive.contexttocall.com/signin", "_blank");
    }


}
