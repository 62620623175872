import { Component, OnInit, HostListener, Inject, ViewEncapsulation } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Router, NavigationEnd } from "@angular/router";
import { StyleService } from "../shared/services/style.service";
import { CookieService } from "../shared/services/cookie-service";
import { SigninService } from "../account/sign-in/sign-in.service";

declare var $: any;
declare var require: any;

@Component({
  selector: "app-home",
  encapsulation: ViewEncapsulation.None,
  templateUrl: "./home-layout.component.html",
  styleUrls: [
    "../../assets/css/microsoftbot.css",
    "../../../node_modules/font-awesome/css/font-awesome.css"
  ]
})
export class HomeLayoutComponent implements OnInit {
  
  userLoggedIn: boolean = false;
  enableChatBot: boolean = false;
  constructor(@Inject(DOCUMENT) document, private router: Router, private signInService: SigninService, private styleService: StyleService, private _cookie: CookieService) { }

  ngOnInit(): any {
    this.styleService.addStyle(
      "showcase",
      require("../../assets/css/showcase.css")
    );

    const loggedProfile: any = this._cookie.GetCookies('loggeduser');
    if (loggedProfile != null && loggedProfile != '') {
      this.userLoggedIn = true;
    } else {
      this.userLoggedIn = false;
    }

    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    var button = document.querySelector("#chatbot-box-button");

    button.addEventListener("click", event => {
      var menu = document.querySelector("#chatbot-window");
      if (this.enableChatBot === false) {
        menu.setAttribute("src", "https://contexttocall.com:81/");
        // menu.setAttribute("src", "https://test.vgroupinc.com:85/");
        this.enableChatBot = true;
        setTimeout(() => {
          if (menu.className == "bot-hide") {
            menu.className = "bot-show";
            button.className = "chatbot-box float-btn";
          } else {
            menu.className = "bot-hide";
            button.className = "message-box";
          }
        }, 700);
      } else {
        if (menu.className == "bot-hide") {
          menu.className = "bot-show";
          button.className = "chatbot-box float-btn";
        } else {
          menu.className = "bot-hide";
          button.className = "message-box";
        }
      }
    });

    function annboard() {
      document.getElementById("chatbot-window").className = "bot-hide";
      document
        .getElementById("chatbot-box-button")
        .setAttribute("class", "message-box");
    }
  }

  @HostListener("window:scroll", ["$event"])
  onWindowScroll(e) {
    if (window.pageYOffset > 300) {
      let element = document.getElementById("mainNav");
      element.classList.add("navbar-shrink");

    } else {
      let element = document.getElementById("mainNav");
      element.classList.remove("navbar-shrink");

    }
  }

  openChatBot() {
    this.enableChatBot = true;
  }
  ngOnDestroy() {
    this.styleService.removeStyle("showcase");
  }

  goToTrusted() {
    // console.log(document.querySelector("#contact").clientHeight)

    if (this.router.url == "/") {
      doFocusAction();
    } else {
      this.router.navigate(["/"]);
      setTimeout(function () {
        doFocusAction();
      }, 300);
    }
    function doFocusAction() {
      var contactDiv = document.querySelector("#contact");
      contactDiv.scrollIntoView({ behavior: "smooth" });
    }
  }

  logoutUser() {
    const logged = this._cookie.GetCookies('loggeduser');
    if (logged != null) {
      this.signInService.LogoutUser(logged.key).subscribe(
        (data) => {
          const resp: any = data;
          if (resp.status == "200" || resp.status == "201" || resp.status == "202") {
            this._cookie.isLoggedOut = true;
            this._cookie.logOutCookies();            
            this.userLoggedIn = false;
          }
        }, (error) => {
          console.log(error);
        }
      );
    } else {
      this._cookie.logOutCookies();
      this.userLoggedIn = false;
    }
  }
}